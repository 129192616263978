import * as React from 'react';
import Helmet from 'react-helmet';

import Tags from '../Tags';

const PostPage = ({ frontmatter, html }) => (
  <div className="blog-post-container">
    <Helmet title={`Van L. — ${frontmatter.title}`} />
    <div className="blog-post">
      <h2>{frontmatter.title}</h2>
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <Tags tags={frontmatter.tags} />
    </div>
  </div>
);

export default PostPage;
