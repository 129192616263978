import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '../components/Layout';
import PostPage from '../components/PostPage';

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        date
        tags
      }
    }
  }
`;

export default ({ data: { markdownRemark: post } }) => (
  <Layout>
    <PostPage {...post} />
  </Layout>
);
