import { Link } from 'gatsby';
import * as React from 'react';

import Icon from '../Icon';
import './Tags.scss';

interface Props {
  tags: string[];
}

const Tags = ({ tags = [] }: Props) => (
  <span className="tag-links">
    {tags.length > 0 && <Icon icon="tag" />}
    {tags
      .map((tag: string) => (
        <span key={tag}>
          <Link to={`posts/tags/${tag}`}>{tag}</Link>
        </span>
      ))
      .reduce((acc: string[], el: React.ReactElement) => (acc === null ? [el] : [...acc, ', ', el]), null)
    }
  </span>
);

export default Tags;
